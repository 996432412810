import { v4 as uuidv4 } from "uuid";
import { snippetIF } from "../../data/types/snippets";
import { downloadSnippet } from "./syncSnippet";
import { getUserId } from "../signinup/handleTokens";
import { renderCssAndHtmlForSnippet } from "../snippets/renderCssAndHtmlForSnippet";

const msgDownloadSuccessOwnSnippet =
  "Your snippet is added to your local collection.";
const msgDownloadSuccessOtherSnippet =
  "A copy of the snippet is added to your local collection and the snippet got a popularity point.";

export const downloadSnippetFromGalleryToLocal = async ({
  snippet,
}: {
  snippet: snippetIF;
}) => {
  const serverSnippet = await downloadSnippet(snippet.id);

  if (!serverSnippet) {
    return {
      msg: "Error: Failed to download snippet.",
    };
  }

  const userId = getUserId();
  const newSnippet = {
    ...serverSnippet,
    owner: userId,
    id: uuidv4(),
    public: false,
    data:
      typeof serverSnippet.data === "string"
        ? JSON.parse(serverSnippet.data)
        : serverSnippet.data,
  } as snippetIF;

  const msg =
  snippet.owner === newSnippet.owner
    ? msgDownloadSuccessOwnSnippet
    : msgDownloadSuccessOtherSnippet;
  const snippetWithCssAndHtmlRendered = await renderCssAndHtmlForSnippet(newSnippet);

  return {
    msg,
    newSnippet: snippetWithCssAndHtmlRendered,
  };
};
