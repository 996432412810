import { metaIF, itemIF, TObject, EMetaFieldTypes } from "../../types/item";

import { getValueStr } from "../../../lib/reducer/items/getItemData";
import { standardUnits, helpObj } from "./utils";

const backgroundFields = {
  size: {
    name: "size",
    type: EMetaFieldTypes.select,
    options: [
      { id: "value" },
      { id: "closest-side" },
      { id: "closest-corner" },
      { id: "farthest-side" },
      { id: "farthest-corner" },
    ],
  },
  sizeX: {
    name: "sizeX",
    type: EMetaFieldTypes.number,
    units: standardUnits,
    dependentOn: {
      field: "size",
      values: ["value"],
    },
  },
  sizeY: {
    name: "sizeY",
    type: EMetaFieldTypes.number,
    units: standardUnits,
    dependentOn: {
      field: "size",
      values: ["value"],
    },
  },
  positionX: {
    name: "positionX",
    type: EMetaFieldTypes.number,
    units: standardUnits,
  },
  positionY: {
    name: "positionY",
    type: EMetaFieldTypes.number,
    units: standardUnits,
  },
};

const backgroundGroups = {
  sizeG: {
    name: "sizeG",
    fields: ["size", "sizeX", "sizeY"],
    display: ["size", "X", "Y"],
  },
  positionG: {
    name: "positionG",
    fields: ["positionX", "positionY"],
    display: ["position X", "Y"],
  },
};

export const CSS_PROPERTIES_BACKGROUND: metaIF[] = [
  {
    name: "background-attachment",
    category: ["BACKGROUND"],
    addToShorthand: "background-attachment",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "scroll",
          },
          {
            id: "fixed",
          },
          {
            id: "local",
          },
        ],
        help:
          "### background-attachment  \n" +
          "`scroll`: The image scrolls with the page.  \n" +
          "`fixed`: When the page scrolls the image does not scroll with it, " +
          "but stays fixed at its position.  \n" +
          "`local`: The image does not scroll with the page, " +
          "but with the content of its container. " +
          "To allow the content of the container to scroll, " +
          "set the `overflow` property on the container to `scroll` or `auto`.",
      },
    ],

    default: { main: { value: "scroll" } },
  },
  {
    name: "background-clip",
    category: ["BACKGROUND"],
    addToShorthand: "background-clip",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "border-box",
          },
          {
            id: "padding-box",
          },
          {
            id: "content-box",
          },
          {
            id: "text",
          },
        ],
        help:
          "### background-clip  \n" +
          "If an element has a `border`, `padding` and `content`, you can define with the help of the `background-clip` property if a background should reach up to the outer `border`, `padding` or just the box around the `content`. If the `border` is transparent (alpha value of the color is less than 100) and you select `border box`, then the background image shines through the border.   \n" +
          "When `background-clip: text` is used together with the `text-fill-color` (category TEXT) property with a `color` with transparency, then you can display one or more `background-images` or `-gradients` as text color.  \n" +
          "*Tip*: with `background-clip: text` is `background` within the text and the rest of the background is white. To add the image to the rest of the background as well, add a `::before` pseudo element to the text container, define `position: absolute` for `::before`, set `top`, `right`, `bottom` and `left` (category POSITION) to 0 and add the background image. If `text-fill-color` on the text-container is 100% transparent (alpha in the color is 0), then text and background have the same background image, the text is invisible. To set a `text-shadow` on the text, add the same text as content in `::before` and apply the text shadow.  \n" +
          "For more information see for example [codrops - Experiments with background-clip: text](https://tympanus.net/codrops/2011/12/12/experiments-with-background-clip-text/)",
      },
    ],
    default: {
      main: {
        value: "border-box",
      },
    },
  },
  {
    name: "background-color",
    category: ["BACKGROUND"],
    fieldsOrder: ["main", "color"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "value" }, { id: "transparent" }],
        canBeInherited: true,
        canBeInitial: true,
        onOff: true,
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        dependentOn: { field: "main", values: ["value"] },
      },
    ],
    group: [
      {
        name: "backgroundColorG",
        fields: ["main", "color"],
        display: ["select", "color"],
      },
    ],
    default: {
      main: { value: "value" },
      color: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },

  {
    name: "background-image",
    category: ["BACKGROUND"],
    addToShorthand: "background",
    // shorthandPropertyOrder: ["THIS", "background-color"],
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fieldsOrder: ["main", "url", "svg"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "url" }, { id: "svg" }, { id: "none" }],
        onOff: true,
      },
      {
        name: "url",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: { field: "main", values: ["url"] },
        wrapperFn: (valueObj) => `url("${valueObj.value}")`,
        help:
          "### url background-image  \n" +
          "Enter the address of any publicly available image. In Chrome, for example, you can right click on an image and then copy its address with `Copy image address`.  \n" +
          "The `image` field is enabled when the `background` type `image` is selected.  \n" +
          helpObj.pileBackgroundImages,
      },
      {
        name: "svg",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: { field: "main", values: ["svg"] },
        wrapperFn: (valueObj) => {
          return `url("data:image/svg+xml;utf8,${valueObj.value}")`;
        },
        help:
          "### SVG background-image  \n" +
          "Enter here the code of the background-image SVG. You can copy it from the CSSexY CODE view.",
      },
    ],
    group: [
      {
        name: "bgImgG",
        fields: ["main", "url", "svg"],
        display: ["select", "url", "svg"],
      },
    ],
    default: {
      main: {
        value: "url",
      },
      url: {
        value: "https://cssexy.s3.eu-west-2.amazonaws.com/fern.jpg",
      },
      svg: {
        value:
          "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stop-color='%23F00'/><stop offset='90%' stop-color='%23fcc'/> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%'/></svg>",
      },
    },
  },

  {
    name: "background-linear-gradient",
    category: ["BACKGROUND"],
    addToShorthand: "background",
    // shorthandPropertyOrder: ["THIS", "background-color"],
    shorthandSeparator: ", ",
    addMultipleTimes: true,

    fieldsOrder: ["main", "linearAngle", "gradientColors"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "linear-gradient" },
          { id: "repeating-linear-gradient" },
        ],
        dontDisableDependentOnField: true,
      },
      {
        name: "linearAngle",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        default: {
          value: 0,
          unit: "deg",
        },
        dependentOn: {
          field: "main",
          values: ["linear-gradient", "repeating-linear-gradient"],
        },
      },
      {
        name: "gradientColors",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
      backgroundFields.size,
      backgroundFields.sizeX,
      backgroundFields.sizeY,
      backgroundFields.positionX,
      backgroundFields.positionY,
    ],

    group: [
      {
        name: "type",
        fields: ["main"],
        display: ["gradient type"],
      },
      {
        name: "angle",
        fields: ["linearAngle"],
        display: ["angle"],
        help:
          "### angle  \n" +
          "Set here the `angle` for the linear gradient. 0 means bottom to top, 90 left to right, -90 right to left and 180 top to bottom.",
      },
      {
        name: "gradientColorsG",
        display: ["color steps"],
        fields: ["gradientColors"],
      },
    ],

    wrapperForGroup: [
      {
        name: "linearGradientGW",
        fields: ["main", "linearAngle", "gradientColors"],
        wrapperFn: ({ gradientColors, linearAngle, main }) => {
          let colorStr = "";
          gradientColors.value.forEach((c: itemIF) => {
            const valObjColor: TObject = c.value?.color || ({} as TObject);
            const cStr = getValueStr({ valObj: valObjColor });
            const valObjWidth = c.value?.width || ({} as TObject);
            const widthStr =
              c.value?.widthValueOrNone?.value === "none"
                ? ""
                : ` ${getValueStr({ valObj: valObjWidth })}`;
            colorStr += `${colorStr === "" ? "" : ", "}${cStr}${widthStr}`;
          });
          return `${main.value}(${
            linearAngle.onOff ? `${getValueStr({ valObj: linearAngle })}, ` : ""
          }${colorStr})`;
        },
      },
    ],

    default: {
      main: {
        value: "linear-gradient",
      },
      linearAngle: {
        value: 0,
        unit: "deg",
      },
      gradientColors: {
        value: [
          {
            id: 1,
            name: "gradientColors",
            position: 1,
            value: {
              color: { value: { r: 255, g: 0, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 0,
                unit: "%",
              },
            },
          },
          {
            id: 2,
            name: "gradientColors",
            position: 2,
            value: {
              color: { value: { r: 255, g: 255, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 50,
                unit: "%",
              },
            },
          },
          {
            id: 3,
            name: "gradientColors",
            position: 3,
            value: {
              color: { value: { r: 0, g: 255, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 100,
                unit: "%",
              },
            },
          },
        ] as itemIF[],
      },
    },
    help: helpObj.pileBackgroundImages,
  },
  {
    name: "background-position",
    category: ["BACKGROUND"],
    addToShorthand: "background-position",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fieldsOrder: ["X", "Y"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        units: standardUnits,
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeCalculated: true,
        units: standardUnits,
      },
    ],

    default: {
      X: { value: 0, unit: "%" },
      XSelect: { value: "value" },
      XCalculate: { value: "0.5rem - 1px" },
      Y: { value: 0, unit: "%" },
      YSelect: { value: "value" },
      YCalculate: { value: "0.5rem - 1px" },
    },
    help:
      "### background-position  \n" +
      "See for example [w3schools - CSS background-position Property](https://www.w3schools.com/cssref/pr_background-position.asp) and [CSS Tricks - A Couple of Use Cases for Calc()](https://css-tricks.com/a-couple-of-use-cases-for-calc/)",
  },

  {
    name: "background-radial-gradient",
    category: ["BACKGROUND"],
    addToShorthand: "background",
    // shorthandPropertyOrder: ["THIS", "background-color"],
    shorthandSeparator: ", ",
    addMultipleTimes: true,

    fieldsOrder: [
      "main",
      "endingShape",
      "size",
      "sizeX",
      "sizeY",
      "positionX",
      "positionY",
      "gradientColors",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "radial-gradient" },
          { id: "repeating-radial-gradient" },
        ],
        dontDisableDependentOnField: true,
        help: "### radial-gradient and repeating radial gradient  \nSee for example [CSS-Tricks - Radial Gradient Recipes](https://css-tricks.com/radial-gradient-recipes/) and [CSS-Tricks - Why Do We Have `repeating-linear-gradient` Anyway?](https://css-tricks.com/why-do-we-have-repeating-linear-gradient-anyway/)",
      },
      {
        name: "gradientColors",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
      {
        name: "endingShape",
        type: EMetaFieldTypes.select,
        options: [{ id: "ellipse" }, { id: "circle" }],
      },
      backgroundFields.size,
      backgroundFields.sizeX,
      backgroundFields.sizeY,
      backgroundFields.positionX,
      backgroundFields.positionY,
    ],

    group: [
      {
        name: "type",
        fields: ["main"],
      },
      {
        name: "endingShapeG",
        fields: ["endingShape"],
        display: ["shape"],
      },
      backgroundGroups.sizeG,
      backgroundGroups.positionG,
      {
        name: "gradientColorsG",
        display: ["color steps"],
        fields: ["gradientColors"],
      },
    ],

    wrapperForGroup: [
      {
        name: "radialGradientGW",
        fields: [
          "main",
          "endingShape",
          "size",
          "sizeX",
          "sizeY",
          "positionX",
          "positionY",
          "gradientColors",
        ],
        wrapperFn: (
          {
            main,
            endingShape,
            size,
            sizeX,
            sizeY,
            positionX,
            positionY,
            gradientColors,
          },
          items
        ) => {
          const endingShapeStr = endingShape?.onOff
            ? `${endingShape.value} `
            : "";

          let sizeStr = "";
          if (size?.onOff) {
            if (size.value === "value") {
              sizeStr = `${getValueStr({ valObj: sizeX })}`;
              if (endingShapeStr !== `circle `) {
                sizeStr += ` ${getValueStr({ valObj: sizeY })}`;
              }
            } else {
              sizeStr = size.value;
            }
          }

          const positionStr = positionX?.onOff
            ? ` at ${getValueStr({
                valObj: positionX,
              })} ${getValueStr({ valObj: positionY })}`
            : "";

          let colorStr = "";
          gradientColors.value.forEach((item: itemIF) => {
            if (item.value) {
              const colStr = `${getValueStr({
                valObj: item.value.color,
              })}`;
              const width =
                item.value.widthValueOrNone.value === "value"
                  ? ` ${getValueStr({ valObj: item.value.width })}`
                  : "";
              const space = colorStr === "" ? "" : ", ";
              colorStr += `${space}${colStr}${width}`;
            }
          });

          return `${main.value}(${endingShapeStr}${sizeStr}${positionStr}${
            endingShapeStr !== "" || sizeStr !== "" || positionStr !== ""
              ? ", "
              : ""
          }${colorStr})`;
        },
      },
    ],

    default: {
      main: {
        value: "radial-gradient",
      },
      endingShape: {
        value: "circle",
      },
      size: {
        value: "value",
      },
      sizeX: { value: 50, unit: "px" },
      sizeY: { value: 50, unit: "px" },
      positionX: { value: 100, unit: "%" },
      positionY: { value: 50, unit: "%" },
      gradientColors: {
        value: [
          {
            id: 1,
            name: "gradientColors",
            position: 1,
            value: {
              color: { value: { r: 255, g: 0, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 0,
                unit: "%",
              },
            },
          },
          {
            id: 2,
            name: "gradientColors",
            position: 2,
            value: {
              color: { value: { r: 255, g: 255, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 50,
                unit: "%",
              },
            },
          },
          {
            id: 3,
            name: "gradientColors",
            position: 3,
            value: {
              color: { value: { r: 0, g: 255, b: 0, a: 1 } },
              widthValueOrNone: { value: "value" },
              width: {
                value: 100,
                unit: "%",
              },
            },
          },
        ] as itemIF[],
      },
    },
    help: helpObj.pileBackgroundImages,
  },

  {
    name: "gradientColors",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["color", "widthValueOrNone", "width"],
    fields: [
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
      {
        name: "widthValueOrNone",
        type: EMetaFieldTypes.select,
        smallInput: true,
        options: [{ id: "value" }, { id: "none" }],
        onOff: true,
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "widthValueOrNone",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "colorWidthG",
        fields: ["color", "widthValueOrNone", "width"],
        display: ["color", "width", "value"],
      },
    ],
    default: {
      color: { value: { r: 255, g: 0, b: 0, a: 1 } },
      widthValueOrNone: { value: "value" },
      width: {
        value: 33.33,
        unit: "%",
      },
    },
  },

  {
    name: "background-repeat",
    category: ["BACKGROUND"],
    addToShorthand: "background-repeat",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "repeat",
          },
          {
            id: "repeat-x",
          },
          {
            id: "repeat-y",
          },
          {
            id: "no-repeat",
          },
        ],
      },
    ],
    default: { main: { value: "no-repeat" } },
  },
  {
    name: "background-size",
    category: ["BACKGROUND"],
    addMultipleTimes: true,
    addToShorthand: "background-size",
    shorthandSeparator: ", ",
    fieldsOrder: ["main", "width", "height"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "auto" },
          {
            id: "cover",
          },
          {
            id: "contain",
          },
          {
            id: "value",
          },
        ],
        help:
          "### background-size  \n" +
          "`auto` is the default and leaves the image in its original size.  \n" +
          "`cover` resizes the image fitting the container height and `contain` the container width.  \n" +
          "If you want to set the `width` and `height` manually, select `value` and enter the width and height in pixel, rem or relative to the container dimensions in %.",
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],

    group: [
      {
        name: "sizeG",
        fields: ["main", "width", "height"],
        display: ["size", "width", "height"],
      },
    ],

    default: {
      main: { value: "auto" },
      width: { value: 50, unit: "%" },
      height: { value: 50, unit: "%" },
    },
  },
  {
    name: "background-origin",
    category: ["BACKGROUND"],
    addToShorthand: "background-origin",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "padding-box",
          },
          {
            id: "border-box",
          },
          {
            id: "content-box",
          },
        ],
        onOff: true,
        help:
          "### background-origin  \n" +
          "When there is a border around the element and there is padding and content inside the element, " +
          "then `background-origin` controls if the image starts at the outer edge of the element (`border-box`), " +
          "the inner edge of its border (`padding-box`) or inside the padding of the element (`content-box`).",
      },
    ],
    default: { main: { value: "padding-box" } },
  },
];
