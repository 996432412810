import { metaIF, EMetaFieldTypes } from "../../types/item";

export const svgHelptexts = {
  "stroke-linecap":
    "### stroke-linecap  \n" +
    "Describes the shape of the end points in an open path in `path`, `line` or `text`.  \n" +
    "`butt`: is the default and ends sharp with the end points.  \n" +
    "`round`: draws a circle around the end points.  \n" +
    "`square`: draws a square around the end points.",
  "stroke-linejoin":
    "### stroke-linejoin  \nSee for example [MDN - stroke-linejoin](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linejoin)",
};

export const CSS_PROPERTIES_SVG: metaIF[] = [
  {
    name: "fill",
    category: ["SVG"],
    fieldsOrder: ["type", "color"],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [{ id: "color" }, { id: "none" }],
        canBeInherited: true,
        canBeInitial: true,
        onOff: true,
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "type",
          values: ["color"],
        },
      },
    ],
    group: [
      {
        name: "typeG",
        fields: ["type", "color"],
        display: ["select", "color"],
      },
    ],
    default: {
      type: { value: "color" },
      color: {
        value: { r: 235, g: 166, b: 63, a: 1 },
      },
    },
  },
  {
    name: "fill-rule",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "nonzero" }, { id: "evenodd" }],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["fill-rule"],
      },
    ],
    default: {
      main: {
        value: "evenodd",
      },
    },
    help: "### fill-rule  \nSee for example [MDN - fill-rule](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill-rule)",
  },

  {
    name: "stroke",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "value",
          },
        ],
        onOff: true,

        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main", "color"],
        display: ["select", "stroke"],
      },
    ],
    default: {
      main: {
        value: "value",
      },
      color: {
        value: { r: 60, g: 188, b: 195, a: 1 },
      },
    },
  },
  {
    name: "stroke-width",
    javascript: "strokeWidth",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["stroke-width"],
      },
    ],
    default: {
      main: {
        value: 1,
      },
    },
  },
  {
    name: "stroke-dasharray",
    javascript: "strokeDasharray",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        onOff: true,
        help: "### stroke-dasharray  \nComma separated list of integers, if which describes first the length of a dash and then of a space. You can add a sequence of as many dashes and spaces as you wish. The pattern is then repeated as often as it fits on the path.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["stroke-dasharray"],
      },
    ],
    default: {
      main: {
        value: "10,10,20,10",
      },
    },
  },
  {
    name: "stroke-linecap",
    javascript: "strokeLinecap",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "butt" }, { id: "round" }, { id: "square" }],
        help: svgHelptexts["stroke-linecap"],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["stroke-linecap"],
      },
    ],
    default: {
      main: {
        value: "round",
      },
    },
  },
  {
    name: "stroke-miterlimit",
    javascript: "strokeMiterlimit",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### stroke-miterlimit attribute  \n" +
          "Presentation attribute defining a limit on the ratio of the miter length to the stroke-width used to draw a miter join. When the limit is exceeded, the join is converted from a miter to a bevel.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["stroke-miterlimit"],
      },
    ],
    default: {
      main: {
        value: 10,
      },
    },
  },
  {
    name: "stroke-linejoin",
    javascript: "strokeLinejoin",
    category: ["SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "miter" }, { id: "round" }, { id: "bevel" }],
        help: svgHelptexts["stroke-linejoin"],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["stroke-linejoin"],
      },
    ],
    default: {
      main: {
        value: "miter",
      },
    },
  },
];
