import {
  actionIF,
  itemIF,
  itemAttributeChangeIF,
  metaIF,
  metaFieldIF,
} from "../../../data/types/item";
import { getMetaWithAllAndCanBeFields } from "../../meta/getMetaWithAllAndCanBeFields";

const clone = require("rfdc")();

const switchFieldsOfGroupOnOrOff = ({
  item,
  fieldName,
  meta,
}: {
  item: itemIF;
  fieldName: string;
  meta?: metaIF;
}) => {
  const cItem = clone(item);

  const fieldsInGroup = [] as string[];
  meta?.group?.forEach((g) => {
    if (g.fields.includes(fieldName)) {
      g.fields.forEach((gf) => fieldsInGroup.push(gf));
    }
  });

  const onOrOff = !cItem.value?.[fieldName]?.onOff ? true : false;

  if (fieldsInGroup.length > 0) {
    fieldsInGroup.forEach((name) => {
      cItem.value = {
        ...(cItem.value ?? {}),
        [fieldName]: {
          ...(cItem.value[fieldName] ?? {}),
          onOff: onOrOff,
        },
      };
    });
  } else {
    const onOrOff = !cItem.value?.[fieldName]?.onOff ? true : false;
    cItem.value = {
      ...(cItem.value ?? {}),
      [fieldName]: {
        ...(cItem.value[fieldName] ?? {}),
        onOff: onOrOff,
      },
    };
  }

  return cItem;
};

export const changeAutoGeneratedSelect = ({
  item,
  fieldName,
  newValue,
  meta,
}: {
  item: itemIF;
  fieldName: string;
  newValue: any;
  meta?: metaIF;
}) => {

  console.log("changeAutoGeneratedSelect", item, fieldName, newValue, meta);
  const mainFieldName = fieldName.replace("Select", "");
  const newItem = clone(item) as itemIF;
  if (!newItem.value) {
    newItem.value = {} as { [key: string]: any };
  }

  if (newValue === "value") {
    newItem.value[fieldName] = { value: "value" };
    if (!newItem.value[mainFieldName]) {
      if (meta?.default && meta.default[mainFieldName]) {
        newItem.value[mainFieldName] = meta.default[mainFieldName];
      }
    }
    return newItem;
  }
  if (newValue === "calculate") {
    newItem.value[fieldName] = { value: "calculate" };
    const calculateFieldName = `${mainFieldName}Calculate`;
    if (!newItem.value[calculateFieldName]) {
      if (meta?.default && meta.default[calculateFieldName]) {
        newItem.value[calculateFieldName] = meta.default[calculateFieldName];
      } else {
        newItem.value[calculateFieldName] = { value: "0.5rem - 1px" };
      }
    }
    return newItem;
  }
  newItem.value[mainFieldName] = { value: newValue };
  return newItem;
};

type TDataMetaCondition = {
  value: number;
  valueStr: string;
};

const isValidCheckMetaConditionInner = ({
  fieldMeta,
  data,
}: {
  fieldMeta: metaFieldIF;
  data: TDataMetaCondition;
}) => {
  const { value, valueStr } = data;
  if (fieldMeta.conditions) {
    if (fieldMeta.conditions.greaterOrEqual0 && value < 0) {
      return false;
    }
    if (fieldMeta.conditions.lessOrEqual1 && value > 1) {
      return false;
    }
    if (fieldMeta.conditions.regex) {
      const pattern = new RegExp(fieldMeta.conditions.regex, "i");
      if (!pattern.test(valueStr)) {
        return false;
      }
    }
    if (fieldMeta.conditions.min && value < fieldMeta.conditions.min) {
      return false;
    }
    if (fieldMeta.conditions.max && value > fieldMeta.conditions.max) {
      return false;
    }
  }
  return true;
};

export const changeItemIFValueInner = ({
  i,
  data,
}: {
  i: itemIF;
  data: itemAttributeChangeIF;
}) => {
  const { fieldName, itemType, valueType, value } = data;
  if (!itemType) {
    console.error("No meta data found for ", i);
    return i;
  }

  const meta = getMetaWithAllAndCanBeFields({
    itemType: itemType,
    item: i,
  });

  if (!meta) {
    console.error("No meta data found for ", i);
    return i;
  }

  if (valueType === "onOff") {
    // switch the onOff value of the field, or, if the field is part of a group, all fields of the group
    const newI = switchFieldsOfGroupOnOrOff({
      meta,
      item: i,
      fieldName: fieldName!,
    });
    return newI;
  }

  // add value and calculate to item value, when value or calculate is selected in auto generated Select field
  if (fieldName?.endsWith("Select")) {
    const newItem = changeAutoGeneratedSelect({
      item: i,
      fieldName,
      newValue: value,
    });

    return newItem || i;
  }

  const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);

  if (fieldMeta && !valueType) {
    const data = {} as TDataMetaCondition;
    if (valueType === "number") {
      data.value = value;
    } else if (valueType === "string") {
      data.valueStr = value;
    }
    const areConditionsMet = isValidCheckMetaConditionInner({
      data,
      fieldMeta,
    });
    if (!areConditionsMet) {
      return i;
    }
  }

  let newValue = value;
  if (
    fieldName === "gradientId" &&
    i.value &&
    i.value[fieldName] &&
    i.value[fieldName].value
  ) {
    newValue = 0;
  }

  // if background-image svg: remove empty spaces and line breaks between brackets >< and replace "" with '
  if (meta?.name === "background-image" && fieldName === "svg") {
    newValue = value
      .replace(/>(\s)+</g, "><")
      .replace(/<style>(\s)+/g, "<style>")
      .replace(/{(\s)+/g, "{")
      .replace(/;(\s)+/g, ";")
      .replace(/}(\s)+/g, "}")
      .replace(/"/g, "'");
  }

  if (!fieldName) {
    console.error("No fieldname in ", data);
    return i;
  }

  const changeFieldKey = valueType || "value";

  const newValueField =
    i.value && i.value[fieldName]
      ? { ...i.value[fieldName], [changeFieldKey]: newValue }
      : { [changeFieldKey]: newValue };

  return {
    ...i,
    value: {
      ...i.value,
      [fieldName]: newValueField,
    },
  } as itemIF;
};

export const changeItemValue = ({
  action,
  items,
  editItemId,
}: {
  action: actionIF;
  items: itemIF[];
  editItemId: number;
}): { items: itemIF[]; editItemId: number } => {
  const fieldGroupName = action.fieldGroupName;
  const fieldName = action.fieldName;
  const itemType = action.itemType;
  const type = action.type;
  const valueType = action.valueType;
  const value = action.value;
  const addOrRemoveEditItemId = action.addOrRemoveEditItemId;

  const data = {
    fieldGroupName,
    fieldName,
    itemType,
    type,
    value,
    valueType,
  } as itemAttributeChangeIF;

  const editItem = fieldGroupName
    ? items.find((i) => i.id === addOrRemoveEditItemId)
    : items.find((i) => i.id === editItemId);
  if (!editItem) {
    console.warn("changeItemValue editItem not found");
    return {
      items,
      editItemId,
    };
  }

  if (fieldGroupName) {
    const fieldGroup = editItem.value && editItem?.value[fieldGroupName];
    if (!fieldGroup) {
      console.warn("SET_NEW_ITEM_VALUE: no fieldGroup");
      return { items, editItemId };
    }

    const itemId = action.editItemId;
    if (!itemId && itemId !== 0) {
      console.warn("SET_NEW_ITEM_VALUE: no editItemId for fieldGroup");
      return { items, editItemId };
    }

    const fieldGroupEditItem = fieldGroup.value.find(
      (i: itemIF) => i.id === itemId
    );
    if (!fieldGroupEditItem) {
      console.warn("SET_NEW_ITEM_VALUE: no fieldGroupEditItem");
      return { items, editItemId };
    }

    const newItem = changeItemIFValueInner({
      data,
      i: fieldGroupEditItem,
    });

    const newEditItem = {
      ...editItem,
      value: {
        ...editItem.value,
        [fieldGroupName]: {
          ...fieldGroup,
          value: fieldGroup.value.map((i: itemIF) =>
            i.id === itemId ? newItem : i
          ),
        },
      },
    } as itemIF;
    return {
      items: items.map((i) => {
        if (i.id === editItem.id) {
          return newEditItem;
        }
        return i;
      }),
      editItemId,
    };
  }

  const changedEditItem: itemIF = changeItemIFValueInner({
    data,
    i: editItem,
  });

  return {
    items: items.map((i) => {
      if (i.id === changedEditItem.id) {
        return changedEditItem;
      }
      return i;
    }),
    editItemId,
  };
};
