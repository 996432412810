import { getServerSnippet } from "./getSnippet";
import { createServerSnippet } from "../snippets/createSnippet";
import { updateServerSnippet } from "../snippets/updateSnippet";
import { snippetIF } from "../../data/types/snippets";
import { getUserId } from "../signinup/handleTokens";

export const syncSnippet = async ({
  snippet,
  userId,
}: {
  snippet: snippetIF;
  userId: string;
}) => {
  const editSnippet = {
    ...snippet,
    owner: userId,
  };

  try {
    const serverSnippet = await getServerSnippet(editSnippet.id);
    if (serverSnippet) {
      const updatedSnippet = await updateServerSnippet({ editSnippet });
      if (updatedSnippet) {
        return {
          ...editSnippet,
          updatedAt: updatedSnippet.updatedAt,
        };
      }
      return;
    }

    const response = await createServerSnippet({ editSnippet });
    if (response) {
      const newSnippet = {
        ...editSnippet,
        id: response.id,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
        public: false
      };
      return newSnippet;
    }
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const downloadSnippet = async (snippetId: string) => {
  try {
    const serverSnippet = await getServerSnippet(snippetId);
    if (!serverSnippet) return;

    // if the snippet owner is not the current user, update the copy count by 1

    const userId = getUserId();

    if (serverSnippet.owner !== userId) {
      const newCopyCount = serverSnippet.copyCount
        ? serverSnippet.copyCount + 1
        : 2;
      const serverSnippetWithNewCopyCount = {
        ...serverSnippet,
        copyCount: newCopyCount,
      } as snippetIF;
      const updatedSnippet = await updateServerSnippet({
        editSnippet: serverSnippetWithNewCopyCount,
      });
      return updatedSnippet;
    }

    return serverSnippet as snippetIF;

  } catch (e) {
    console.error((e as Error).message);
  }
};
