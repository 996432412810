import { metaIF, EMetaFieldTypes } from "../../types/item";
import { helpAll } from "../../selectors/constants/all";
import { standardUnits } from "./utils";
import { getValueStr } from "../../../lib/reducer/items/getItemData";

export const CSS_PROPERTIES_OTHER: metaIF[] = [
  {
    name: "appearance",
    category: ["OTHER"],
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "none",
          },
          {
            id: "value",
          },
        ],
      },
      {
        name: "value",
        type: EMetaFieldTypes.string,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "valueOrNone",
        fields: ["main", "value"],
        display: ["appearance", "value"],
        help:
          "### appearance  \n" +
          "See for example [CSS Tricks - appearance](https://css-tricks.com/almanac/properties/a/appearance/)",
      },
    ],

    default: {
      main: {
        value: "none",
      },
      value: {
        value: "button",
      },
    },
  },
  {
    name: "clip-path",
    category: ["OTHER"],
    fieldsOrder: [
      "mainSelectBasicShape",
      "circleCenterX",
      "circleCenterY",
      "circleRadius",
      "ellipseCenterX",
      "ellipseCenterY",
      "xxyyzz",
      "ellipseRadiusVertical",
      "polygon",
      "path",
    ],
    fields: [
      {
        name: "mainSelectBasicShape",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "circle",
          },
          {
            id: "ellipse",
          },
          {
            id: "polygon",
          },
          {
            id: "path",
          },
        ],
      },
      {
        name: "circleCenterX",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["circle"],
        },
      },
      {
        name: "circleCenterY",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["circle"],
        },
      },
      {
        name: "circleRadius",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["circle"],
        },
      },
      {
        name: "ellipseCenterX",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["ellipse"],
        },
      },
      {
        name: "ellipseCenterY",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["ellipse"],
        },
      },
      {
        name: "xxyyzz",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["ellipse"],
        },
      },
      {
        name: "ellipseRadiusVertical",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["ellipse"],
        },
      },
      {
        name: "polygon",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["polygon"],
        },
      },
      {
        name: "path",
        type: EMetaFieldTypes.string,
        onOff: true,
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["path"],
        },
      },
    ],
    group: [
      {
        name: "mainSelectG",
        fields: ["mainSelectBasicShape"],
        display: ["basic shape"],
      },
      {
        name: "circleG",
        fields: ["circleCenterX", "circleCenterY", "circleRadius"],
        display: ["center x", "center y", "radius"],
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["circle"],
        },
      },
      {
        name: "ellipseG",
        fields: [
          "ellipseCenterX",
          "ellipseCenterY",
          "xxyyzz",
          "ellipseRadiusVertical",
        ],
        display: [
          "center x",
          "center y",
          "radius horizontal",
          "radius vertical",
        ],
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["ellipse"],
        },
      },
      {
        name: "polygonG",
        fields: ["polygon"],
        display: ["polygon"],
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["polygon"],
        },
      },
      {
        name: "pathG",
        fields: ["path"],
        display: ["path"],
        dependentOn: {
          field: "mainSelectBasicShape",
          values: ["path"],
        },
      },
    ],
    wrapperForGroup: [
      {
        name: "clipPathWG",
        fields: [
          "mainSelectBasicShape",
          "circleCenterX",
          "circleCenterY",
          "circleRadius",
          "ellipseCenterX",
          "ellipseCenterY",
          "xxyyzz",
          "ellipseRadiusVertical",
          "polygon",
          "path",
        ],
        wrapperFn: ({
          mainSelectBasicShape,
          circleCenterX,
          circleCenterY,
          circleRadius,
          ellipseCenterX,
          ellipseCenterY,
          xxyyzz,
          ellipseRadiusVertical,
          polygon,
          path,
        }) => {
          if (mainSelectBasicShape.value === "circle") {
            return `circle(${getValueStr({
              valObj: circleCenterX,
            })} at ${getValueStr({
              valObj: circleCenterY,
            })} ${getValueStr({
              valObj: circleRadius,
            })})`;
          } else if (mainSelectBasicShape.value === "ellipse") {
            return `ellipse(${getValueStr({
              valObj: xxyyzz,
            })} ${getValueStr({
              valObj: ellipseRadiusVertical,
            })} at ${getValueStr({
              valObj: ellipseCenterX,
            })} ${getValueStr({
              valObj: ellipseCenterY,
            })})`;
          } else if (mainSelectBasicShape.value === "polygon") {
            return `polygon(${polygon.value
              .map((item: any) => {
                return `${getValueStr({
                  valObj: item.value.xPosition,
                })} ${getValueStr({
                  valObj: item.value.yPosition,
                })}`;
              })
              .join(", ")})`;
          } else if (mainSelectBasicShape.value === "path") {
            return `path('${path.value}')`;
          }
          return "";
        },
      },
    ],
    default: {
      mainSelectBasicShape: { value: "polygon" },
      circleCenterX: { value: "50", unit: "%" },
      circleCenterY: { value: "50", unit: "%" },
      circleRadius: { value: "50", unit: "%" },
      ellipseCenterX: { value: "50", unit: "%" },
      ellipseCenterY: { value: "50", unit: "%" },
      xxyyzz: { value: "30", unit: "%" },
      ellipseRadiusVertical: { value: "50", unit: "%" },
      polygon: {
        value: [
          {
            id: 1,
            name: "polygon",
            position: 1,
            value: {
              xPosition: { value: "50", unit: "%" },
              yPosition: { value: "0", unit: "%" },
            },
          },
          {
            id: 2,
            name: "polygon",
            position: 2,
            value: {
              xPosition: { value: "0", unit: "%" },
              yPosition: { value: "100", unit: "%" },
            },
          },
          {
            id: 3,
            name: "polygon",
            position: 3,
            value: {
              xPosition: { value: "100", unit: "%" },
              yPosition: { value: "100", unit: "%" },
            },
          },
        ],
      },
      path: { value: "M 0 0 L 100 0 L 100 100 L 0 100 Z" },
    },
  },
  {
    name: "polygon",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["xPosition", "yPosition"],
    fields: [
      {
        name: "xPosition",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
      },
      {
        name: "yPosition",
        type: EMetaFieldTypes.number,
        units: standardUnits,
        onOff: true,
      },
    ],
    group: [
      {
        name: "polygonG",
        fields: ["xPosition", "yPosition"],
        display: ["X", "Y"],
      }
    ],
    default: {
      xPosition: { value: "50", unit: "%" },
      yPosition: { value: "50", unit: "%" },
    },
  },
  {
    name: "content",
    category: ["OTHER"],
    enabledInSelector: ["::before", "::after"],
    fieldsOrder: ["main", "text", "counter", "attr", "url", "urlStr"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "text",
          },
          {
            id: "counter",
          },
          {
            id: "attr",
          },
          {
            id: "url",
          },
        ],
      },
      {
        name: "text",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["text"],
        },
      },
      {
        name: "counter",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["counter"],
        },
      },
      {
        name: "attr",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["attr"],
        },
      },
      {
        name: "url",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "url",
          },
          {
            id: "svg",
          },
        ],
        dependentOn: {
          field: "main",
          values: ["url"],
        },
      },
      {
        name: "urlStr",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dependentOn: {
          field: "url",
          values: ["url", "svg"],
        },
      },
    ],

    group: [
      {
        name: "mainG",
        display: ["content type"],
        fields: ["main"],
        help:
          "### content  \n" +
          "You can add the content of the ::before or ::after selector as selector attribute or here in the CSS content property. The CSS property, when set, overwrites the selector attribute. The CSS property `content` is more powerful and flexible then the selector attribute.  \n" +
          "Here you can add variables to the content text. The available variables are `counter`, `attr` and `url`.  \n" +
          "You can use CSS content in animations or other transition effects and so change the text, for example, when the state of the element moves on.",
      },
      {
        name: "textG",
        display: ["text"],
        fields: ["text"],
        help: "### text  \n" + helpAll.contentText,
      },
      {
        name: "counterG",
        display: ["counter"],
        fields: ["counter"],
        help:
          "### counter  \n" +
          "Works in combination with `counter-increment` and `counter-reset`. `counter-reset` is set on the parent or somewhere further up in the document, countains the name of the counter and makes the counter start from the smallest value set in `counter-increment`.  \n" +
          '`Counter-increment` and `content` are added to the CSS of the pseudo element. `Counter-increment` contains the name of the counter and the step width. In `content` you wrap the counter variable within `counter(name)`. If you want to add text before and/or after counter(name), the text must be padded in double quotes, like in "section: " counter(name) ":".  \n' +
          "For more information about `counter(name)` in `content` see for example [w3schools - CSS counter-increment Property](https://www.w3schools.com/cssref/pr_gen_counter-increment.asp)",
      },
      {
        name: "attrG",
        display: ["attr"],
        fields: ["attr"],
        help:
          "### attr  \n" +
          "Print HTML attribute in the pseudo-element content. See for example [CSS Tricks - CSS Content - Using Attributes](https://css-tricks.com/css-content)",
      },
      {
        name: "urlUrlStr",
        display: ["type", "url"],
        fields: ["url", "urlStr"],
        help: "### url  \nEnter here an image url or SVG code.",
      },
    ],
    wrapperForGroup: [
      {
        name: "contentG",
        fields: ["main", "text", "counter", "attr", "url", "urlStr"],
        wrapperFn: ({ main, text, counter, attr, url, urlStr }) => {
          if (main.value === "text") {
            const firstQuot = /^\s*["]/.test(text.value) ? "" : '"';
            const lastQuot = /["]\s*$/.test(text.value) ? "" : '"';
            return `${firstQuot}${text.value}${lastQuot}`;
          }
          if (main.value === "counter") {
            return counter.value;
          }
          if (main.value === "attr") {
            return attr.value;
          }
          if (main.value === "url") {
            if (url.value === "url") {
              return `url("${urlStr.value}")`;
            }
            return `url("data:image/svg+xml;charset=UTF-8,${urlStr.value}")`;
          }
          return "";
        },
      },
    ],
    default: {
      main: {
        value: "text",
      },
      text: {
        value: "\\00A9",
      },
      counter: {
        value: `"section: " counter(section) ":"`,
      },
      attr: {
        value: "attr(href)",
      },
      url: {
        value: "svg",
      },
      urlStr: {
        value:
          "<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z'></path></svg>",
      },
    },
  },
  {
    name: "counter-reset",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        onOff: true,
        help: "### counter-reset  \nSee for example [w3schools - counter-reset](https://www.w3schools.com/cssref/pr_gen_counter-reset.asp)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["counter-reset"],
      },
    ],
    default: {
      main: {
        value: "section",
      },
    },
  },
  {
    name: "counter-increment",
    category: ["OTHER"],
    enabledInSelector: ["::before", "::after"],
    fieldsOrder: ["main", "increment"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "increment",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "counterIncrement",
        display: ["counter-increment", "increment"],
        fields: ["main", "increment"],
        wrapperFn: ({ main, increment }) =>
          ` ${main.value}${
            increment.value && increment.value !== 1
              ? ` ${increment.value}`
              : ""
          }`,
        help:
          "### counter-increment  \n" +
          "See for example [w3schools - CSS counter-increment Property](https://www.w3schools.com/cssref/pr_gen_counter-increment.asp)",
      },
    ],

    default: {
      main: {
        value: "section",
      },
      increment: {
        value: 1,
      },
    },
  },
  {
    name: "cursor",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "### Cursor  \n" +
          "Select a mouse cursor icon, which is displayed when the user is hovering over an element.  \n" +
          "For more information see for example [CSS Tricks - Cursor](https://css-tricks.com/almanac/properties/c/cursor/)",
        options: [
          {
            id: "alias",
          },
          {
            id: "all-scroll",
          },
          {
            id: "auto",
          },
          {
            id: "cell",
          },
          {
            id: "context-menu",
          },
          {
            id: "col-resize",
          },
          {
            id: "copy",
          },
          {
            id: "crosshair",
          },
          {
            id: "default",
          },
          {
            id: "e-resize",
          },
          {
            id: "grab",
          },
          {
            id: "grabbing",
          },
          {
            id: "help",
          },
          {
            id: "move",
          },
          {
            id: "n-resize",
          },
          {
            id: "ne-resize",
          },
          {
            id: "nesw-resize",
          },
          {
            id: "ns-resize",
          },
          {
            id: "nwse-resize",
          },
          {
            id: "no-drop",
          },
          {
            id: "none",
          },
          {
            id: "not-allowed",
          },
          {
            id: "pointer",
          },
          {
            id: "progress",
          },
          {
            id: "row-resize",
          },
          {
            id: "s-resize",
          },
          {
            id: "se-resize",
          },
          {
            id: "sw-resize",
          },
          {
            id: "text",
          },
          {
            id: "URL",
          },
          {
            id: "vertical-text",
          },
          {
            id: "w-resize",
          },
          {
            id: "wait",
          },
          {
            id: "zoom-in",
          },
          {
            id: "zoom-out",
          },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["cursor"],
      },
    ],
    default: {
      main: {
        value: "pointer",
      },
    },
  },
  {
    name: "pointer-events",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "auto",
          },
          {
            id: "none",
          },
        ],
        help:
          "### pointer-events  \n" +
          "If set to `none`, then the selected element doesn not react to pointer events like `hover` or `click`. " +
          "If set to `auto`, what is the default, it does.  \n" +
          "For more information see for example [MDN - pointer-events](https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events).",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["pointer-events"],
      },
    ],
    default: {
      main: {
        value: "pointer",
      },
    },
  },
  {
    name: "resize",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "none",
          },
          {
            id: "both",
          },
          {
            id: "horizontal",
          },
          {
            id: "vertical",
          },
          { id: "initial" },
          { id: "inherit" },
        ],
        help: "### resize  \nSee for example [w3schools - CSS resize Property](https://www.w3schools.com/cssref/css3_pr_resize.asp)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["resize"],
      },
    ],
    default: {
      main: {
        value: "none",
      },
    },
  },
  {
    name: "scroll-behavior",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "auto",
          },
          {
            id: "smooth",
          },
        ],
        canBeAuto: true,
        canBeInherited: true,
        canBeInitial: true,
        help: "### resize  \nSee for example [w3schools - CSS resize Property](https://www.w3schools.com/cssref/css3_pr_resize.asp)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["scroll-behavior"],
      },
    ],
    default: {
      main: {
        value: "auto",
      },
    },
  },
  {
    name: "user-select",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "### user-select  \n" +
          "See for example [w3schools - CSS user-select Property](https://www.w3schools.com/cssref/css3_pr_user-select.asp)",
        options: [
          {
            id: "auto",
          },
          {
            id: "none",
          },
          {
            id: "text",
          },
          {
            id: "all",
          },
        ],
      },
    ],
  },
  {
    name: "visibility",
    category: ["OTHER"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        help:
          "### visibility  \n" +
          "See for example [w3schools - CSS visibility Property](https://www.w3schools.com/cssref/pr_class_visibility.asp)",
        options: [
          {
            id: "visible",
          },
          {
            id: "hidden",
          },
          {
            id: "collapse",
          },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["visibility"],
      },
    ],
    default: { main: { value: "hidden" } },
  },
];
